import React, { useState, useEffect } from 'react';
import { Table, Button, DatePicker, Card, Typography, Space, Row, Col, Tag, Modal } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import axios from 'axios';
import DailyBill from '../assets/DailyBill';
import ReactToPrint from "react-to-print";

const { Title, Text } = Typography;

const DailyReports = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [billPopupVisible, setBillPopupVisible] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [summary, setSummary] = useState({
    totalSales: 0,
    totalDiscount: 0,
    netTotal: 0,
    cashSales: 0,
    cardSales: 0,
    totalTransactions: 0,
    serviceChargeTotal: 0,
    totalItemDiscounts: 0
  });

  const pageStyle = `
    @page {
        size: 80mm 297mm;
        margin: 20mm;
    };

    @media all {
        .pagebreak {
            display: none;
        };

    @media print {
        .pagebreak {
            display: block;
            page-break-before: always;
        };
    }
    `;

  useEffect(() => {
    if (selectedDate) {
      fetchDailyTransactions();
    }
  }, [selectedDate]);

  const fetchDailyTransactions = async () => {
    try {
      const formattedDate = selectedDate.format('YYYY-MM-DD');
      const response = await axios.get(`https://bkbordeau.debuxglobal.com/api/reports/daily`, {
        params: {
          date: formattedDate,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      
      const transformedSummary = {
        totalSales: response.data.summary.totalSales || 0,
        totalDiscount: response.data.summary.totalDiscount || 0,
        netTotal: response.data.summary.netTotal || 0,
        cashSales: response.data.summary.cashSales || 0,
        cardSales: response.data.summary.cardSales || 0,
        totalTransactions: response.data.summary.totalTransactions || 0,
        serviceChargeTotal: response.data.summary.totalServiceCharge || 0,
        totalItemDiscounts: 0
      };
      
      setTransactions(response.data.transactions || []);
      setSummary(transformedSummary);
      
    } catch (error) {
      console.error("Error fetching daily report:", error);
    }
  };

  const handlePrint = () => {
    setBillPopupVisible(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCalendarOpen(false);
  };

  const handleOpenChange = (open) => {
    setCalendarOpen(open);
  };

  const StatisticCard = ({ title, value }) => {
    return (
      <Card>
        <Text style={{ fontSize: "16px", fontFamily: 'Nunito', fontWeight: '500' }}>{title}</Text>
        <Title level={4} style={{ fontSize: "22px", fontFamily: 'Nunito', fontWeight: '700', marginTop: '8px' }}>
          {`LKR ${Number(value).toFixed(2)}`}
        </Title>
      </Card>
    );
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      render: (method) => (
        <Tag color={method === "cash" ? "success" : "warning"}>
          {method.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (amount) => `LKR ${Number(amount).toFixed(2)}`,
    },
    {
      title: "Service Charge",
      dataIndex: "service_charge",
      key: "service_charge",
      render: (charge) => `LKR ${Number(charge || 0).toFixed(2)}`,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (discount) => `LKR ${Number(discount).toFixed(2)}`,
    },
    {
      title: "Net Total",
      dataIndex: "net_total",
      key: "net_total",
      render: (netTotal) => `LKR ${Number(netTotal).toFixed(2)}`,
    },
    {
      title: "Time",
      dataIndex: "created_at",
      key: "time",
      render: (date) => {
        const dateObj = new Date(date);
        return dateObj.toLocaleTimeString();
      },
    },
  ];

  return (
    <div style={{ padding: '24px' }}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        marginBottom: '24px'
      }}>
        <Text style={{
          fontSize: "36px",
          fontWeight: "bold",
          color: "#000000",
          fontFamily: "Nunito",
        }}>
          Daily Reports
        </Text>
        <Space size="large">
          <DatePicker 
            onChange={handleDateChange}
            picker="date"
            open={calendarOpen}
            onOpenChange={handleOpenChange}
            allowClear={true}
            format="YYYY-MM-DD"
            style={{ 
              height: '50px',
              width: '200px',
              fontSize: '16px',
              fontFamily: 'Nunito',
              fontWeight: '700'
            }}
          />
          <Button
            type="primary"
            icon={<PrinterOutlined />}
            onClick={handlePrint}
            style={{
              height: '50px',
              width: '180px',
              fontSize: '16px',
              fontFamily: 'Nunito',
              fontWeight: '700'
            }}
          >
            Print Report
          </Button>
        </Space>
      </div>

      <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
        <Col span={8}>
          <StatisticCard title="Total Sales" value={summary.totalSales} />
        </Col>
        <Col span={8}>
          <StatisticCard title="Service Charge Total" value={summary.serviceChargeTotal} />
        </Col>
        <Col span={8}>
          <StatisticCard title="Total Discounts" value={summary.totalDiscount} />
        </Col>
        <Col span={8}>
          <StatisticCard title="Net Total" value={summary.netTotal} />
        </Col>
        <Col span={8}>
          <StatisticCard title="Cash Sales" value={summary.cashSales} />
        </Col>
        <Col span={8}>
          <StatisticCard title="Card Sales" value={summary.cardSales} />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={transactions}
        rowKey="order_id"
        bordered
        scroll={{ x: true }}
      />

      <Modal
        visible={billPopupVisible}
        footer={null}
        onCancel={() => setBillPopupVisible(false)}
        bodyStyle={{ padding: 0 }}
      >
        <div>
          <ReactToPrint
            trigger={() => <Button style={{ margin: "10px" }}>Print this out!</Button>}
            content={() => document.getElementById("dailyBillToPrint")}
            pageStyle={pageStyle}
          />
          <div id="dailyBillToPrint">
            <DailyBill 
              date={selectedDate ? selectedDate.format('MMMM DD, YYYY') : ''}
              summary={summary}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DailyReports;