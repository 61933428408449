import React from "react";
import { Menu, Layout, Typography } from "antd";
import { useLocation, Link } from "react-router-dom";
import LogoIcon from "./SideBarImages/LogoIcon.png";
import DashboardIcon from "./SideBarImages/Dashboard.png";
import MenuIcon from "./SideBarImages/Menu.png";
import OrdersIcon from "./SideBarImages/Orders.png";
import SalesIcon from "./SideBarImages/Sales.png";
import TransactionsIcon from "./SideBarImages/Transactions.png";
import ReportsIcon from "./SideBarImages/Reports.png";
import MReportIcon from "./SideBarImages/MReports.png";
import { useAuth } from "../context/AuthContext";

const { Sider } = Layout;
const { Title } = Typography;

const Sidebar = () => {
  const location = useLocation();
  const path = location.pathname;
  const { user } = useAuth();

  return (
    <Sider width={240} style={{ background: "#1B2728" }}>
      <div
        style={{
          color: "#fff",
          padding: "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={LogoIcon} alt="logo" style={{ width: "23px", height: "24px" }} />
        <Title
          level={5}
          style={{
            color: "#fff",
            margin: "0 0 0 15px",
            textAlign: "center",
            letterSpacing: "1px",
            fontSize: "16px",
            fontWeight: "500",
            fontFamily: "Nunito",
          }}
        >
          Bord'eau Restaurant
        </Title>
      </div>
      <Menu theme="dark" mode="inline" selectedKeys={[path]} style={{ background: "#1B2728", marginTop: "50px" }}>
        {user?.userPosition?.toLowerCase().includes("admin") && (
          <Menu.Item
            key="/dashboard"
            icon={<img src={DashboardIcon} alt="Dashboard" style={{ width: "18px", height: "18px" }} />}
          >
            <Link
              to="/dashboard"
              style={{
                color: "#fff",
                fontFamily: "Nunito",
                fontSize: "16px",
                fontWeight: "500",
                letterSpacing: "1px",
                marginLeft: "15px",
              }}
            >
              Dashboard
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="/menu" icon={<img src={MenuIcon} alt="Menu" style={{ width: "18px", height: "18px" }} />}>
          <Link
            to="/menu"
            style={{
              color: "#fff",
              fontFamily: "Nunito",
              fontSize: "16px",
              fontWeight: "500",
              letterSpacing: "1px",
              marginLeft: "15px",
            }}
          >
            Menu
          </Link>
        </Menu.Item>
        <Menu.Item
          key="/order-management"
          icon={
            <img
              src={OrdersIcon}
              alt="Orders"
              style={{
                width: "18px",
                height: "18px",
              }}
            />
          }
        >
          <Link
            to="/order-management"
            style={{
              color: path === "/order" ? "#F2890D" : "#fff",
              fontFamily: "Nunito",
              fontSize: "16px",
              fontWeight: "500",
              letterSpacing: "1px",
              marginLeft: "15px",
            }}
          >
            Orders
          </Link>
        </Menu.Item>
        <Menu.Item
          key="/ongoing-orders"
          icon={<img src={SalesIcon} alt="Sales" style={{ width: "18px", height: "18px" }} />}
        >
          <Link
            to="/ongoing-orders"
            style={{
              color: path === "/sales" ? "#F2890D" : "#fff",
              fontFamily: "Nunito",
              fontSize: "16px",
              fontWeight: "500",
              letterSpacing: "1px",
              marginLeft: "15px",
            }}
          >
            Tables
          </Link>
        </Menu.Item>
        <Menu.Item
          key="/transactions"
          icon={<img src={TransactionsIcon} alt="Transactions" style={{ width: "18px", height: "18px" }} />}
        >
          <Link
            to="/transactions"
            style={{
              color: "#fff",
              fontFamily: "Nunito",
              fontSize: "16px",
              fontWeight: "500",
              letterSpacing: "1px",
              marginLeft: "15px",
            }}
          >
            Transactions
          </Link>
        </Menu.Item>
        <Menu.Item
          key="/report"
          icon={<img src={ReportsIcon} alt="Transactions" style={{ width: "18px", height: "18px" }} />}
        >
          <Link
            to="/report"
            style={{
              color: "#fff",
              fontFamily: "Nunito",
              fontSize: "16px",
              fontWeight: "500",
              letterSpacing: "1px",
              marginLeft: "15px",
            }}
          >
            Monthly Reports
          </Link>
        </Menu.Item>
        <Menu.Item
          key="/daily-reports"
          icon={<img src={MReportIcon} alt="Transactions" style={{ width: "18px", height: "18px" }} />}
        >
        <Link
          to="/daily-reports"
          style={{
            color: "#fff",
            fontFamily: "Nunito",
            fontSize: "16px",
            fontWeight: "500",
            letterSpacing: "1px",
            marginLeft: "15px",
          }}
        >
          Daily Report
        </Link>
          </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
