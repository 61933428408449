import React from 'react';
import bordeaulogo from "../assets/BillImages/bordeaulogo.png";

const DailyBill = ({ date, summary }) => {
  return (
    <div className="w-80 mx-auto font-mono text-xs leading-tight bg-white shadow-md rounded-lg">
      <div className="p-4 border-b text-center pb-2">
        <div className="text-xl font-bold flex items-center justify-center mx-auto">
          <img src={bordeaulogo} alt="Bord Eau Logo" className="w-20 h-6 mx-auto mb-2" />
        </div>
        <h2 className="font-bold">Balapitiya</h2>
        <p>212 Galle Rd, Balapitiya</p>
        <p>Open 9AM - Close 8PM</p>
        <p>Tel No: 077-756-6503</p>
      </div>
      
      <div className="p-4 space-y-2">
        <div>
          <p className="font-bold text-center">Daily Sales Report</p>
          <p>{date}</p>
        </div>
        
        <div className="border-t border-black pt-2">
          <p className="flex justify-between">
            <span>Total Sales:</span>
            <span>LKR {summary.totalSales.toFixed(2)}</span>
          </p>
          <p className="flex justify-between">
            <span>Service Charge Total:</span>
            <span>LKR {summary.serviceChargeTotal.toFixed(2)}</span>
          </p>
          <p className="flex justify-between">
            <span>Total Item Discounts:</span>
            <span>- LKR {summary.totalItemDiscounts.toFixed(2)}</span>
          </p>
          <p className="flex justify-between">
            <span>Additional Discounts:</span>
            <span>- LKR {summary.totalDiscount.toFixed(2)}</span>
          </p>
          <p className="flex justify-between font-bold">
            <span>Net Total:</span>
            <span>LKR {summary.netTotal.toFixed(2)}</span>
          </p>
          
          <div className="mt-4">
            <p className="flex justify-between">
              <span>Cash Sales:</span>
              <span>LKR {summary.cashSales.toFixed(2)}</span>
            </p>
            <p className="flex justify-between">
              <span>Card Sales:</span>
              <span>LKR {summary.cardSales.toFixed(2)}</span>
            </p>
          </div>
          
          <p className="mt-4">Total Transactions: {summary.totalTransactions}</p>
        </div>
      </div>
      
      <div className="p-4 border-t text-center pt-0">
        <p className="w-full">End of Report</p>
      </div>
    </div>
  );
};

export default DailyBill;